<template>
<div id="c">
  <div class="site_limit">
    <div class="top ">
      <span>
        <a href="/" class="linkHome">Home |</a>
        <h2>Central de Atendimento</h2>
      </span>
    </div>
    </div>
    <div class="site_limit">
      <div class="contact">
        <div v-if="!emailSent" class="form">
            
              <b-form-input type="text" :state="StateName" trim v-model="name" placeholder="Nome"></b-form-input> 
              <b-form-input type="email" :state="StateEmail" trim v-model="email" placeholder="Email"></b-form-input> 
              <b-form-textarea :state="StateMessage" trim v-model="message" placeholder="Mensagem"></b-form-textarea>
              <div class="actions">
                <b-spinner v-if="sending" label="Busy"></b-spinner>
                <button v-else
                    @click="sendEmail()"
                    :disabled="StatelAll"
                    class="btn send">
                    Enviar</button>
              </div>
          </div>
          <div v-else class="form">
            <div class="alert alert-success text-center p-3 clickable" @click="clearForm()">
                Obrigado por entrar em contato!
            </div>
          </div>
          <div class="contactData" v-if="dataUser != undefined">
              <div>
                <span v-if="footer_messages != undefined" class="strong">{{ footer_messages.followus }}</span>
                <div class="socialgrid">
                  <div class="social" v-for="network, index in networks" :key="`n${index}`">
                      <a class="link" :title="network.label" :href="network.url" target="_blank">
                        <inline-svg v-if="verifyAsset(network.icon)" class="icon" :src="require(`@/assets/svg/${network.icon}.svg`)"></inline-svg> 
                        <span v-else>{{ network.label }}</span>
                      </a>
                    </div>
                </div>
              </div>
              <div class="phonegrid">
                  <span v-if="footer_messages != undefined" class="strong">{{ footer_messages.contact_us }}</span>
                  <div class="phone" v-for="phone, index in phones" :key="`p${index}`">
                    <a class="link" :href="(phone.linktype == 1) 
                              ? `tel:${removeNonNumbers(phone.number)}` 
                              : `${whatsappLink}${removeNonNumbers(phone.number)}`"
                              :target="(phone.linktype == 1) ? '_self' : '_blank'">
                    <inline-svg v-if="verifyAsset(phone.icon)" class="icon" :src="require(`@/assets/svg/${phone.icon}.svg`)"></inline-svg>
                    {{ phone.number }} </a>
                  </div>
                  <span v-if="footer_messages != undefined" class="small">{{ footer_messages.attendance }}</span>
              </div>
          </div>
      </div>
      <div class="contactBigData" v-if="dataUser != undefined">
              <div class="addresses">
                <div class="address" :id="`address${index}`" v-for="address, index in addresses" :key="index">
                  <inline-svg v-if="verifyAsset(address.icon)" class="icon" :src="require(`@/assets/svg/${address.icon}.svg`)"></inline-svg>
                  <div class="text"><span>{{ address.street }}, {{ address.number }}</span> <span v-if="address.complement != undefined">{{ address.complement }}</span>
                  <br><span v-if="address.neighborhood != undefined">{{ address.neighborhood }}, </span>{{ address.zipcode }}<br>
                  <span>{{ address.city }}/{{ address.state }}</span>
                  <span v-if="address.country != undefined">{{ address.country }}</span>
                  <span v-if="address.google_maps" @click="showInGoogleMaps(address)" class="clickable small"><br>Veja no Mapa</span>
                  </div>
                </div>
              </div>
      </div>
    </div>
    <GoogleMap :address="google_maps" :reloadNumber="reloadNumberGoogle" />
</div>
</template>

<script>
import GoogleMap from "@/components/googleMap";
import LengthValidator from "@/components/helpers/lengthValidator"
import EmailValidator from "@/components/helpers/emailValidator"
import verifyAsset from '@/components/helpers/verifyAsset';
import axios from 'axios'

export default {
  name: "Contact",
  mixins: [verifyAsset, GoogleMap.mixin],
  components: { GoogleMap },
  props: {
    dataUser: Object,
    reloadCounter: Number,
  },
  created(){
    this.verifyLocalStorage()
  },
  data() {
    return {
      metaData: { 
        'description': 'Entre em contato com a gente!', 
        'addKeywords':'contato',
        'addTitle': 'Contato'
        },
      name: undefined,
      email: undefined,
      message: undefined,
      phones: [],
      networks: [],
      addresses: undefined,
      whatsappLink: undefined,
      emailSent: false,
      sending: false,
      footer_messages: undefined,
    };
  },
  methods:{
    async verifyDataUser(){
      this.emailSent= false;
      this.sending= false;
      if(this.dataUser != undefined){
        this.phones = this.dataUser.meliphones
        this.networks = this.dataUser.melisocialnetwork
        this.addresses = this.dataUser.meliaddress
        this.whatsappLink = this.dataUser.options.whatsapp_options.url
        this.showInGoogleMaps(this.addresses.find(address => address.google_maps));
      }
    },
    async sendEmail(){
      this.sending= true;
      if(this.dataUser != undefined || this.StatelAll == false){
        var data = {name: this.name, email: this.email };
        localStorage.setItem('contactData', JSON.stringify(data) );
        var emailData = {
          name: this.name,
          email: this.email,
          subject: 'Contato pelo site',
          message: this.message,
          store_id: process.env.VUE_APP_STORE_ID
        }
        var headers = { 'Content-Type': 'application/json' }
        await axios.post(`${process.env.VUE_APP_API_URL}/sendmail`, emailData, headers).then(() => {
          this.emailSent= true;
          this.sending= false;
        }).catch(() => {
          this.emailSent= false;
          this.sending= false;
        });
      }
    },
    verifyLocalStorage(){
      this.emailSent= false;
      this.sending= false;
      if(localStorage.getItem('contactData')){
        var data = JSON.parse(localStorage.getItem('contactData'));
        this.name = data.name;
        this.email = data.email;
      }
    },
    clearForm(){
      this.emailSent= false;
      this.sending= false;
      this.message = undefined;
    },
    removeNonNumbers(value){
      return value.replace(/\D/g, '')
    }
  },
  computed: {
    StateName() {
      return LengthValidator.Max(this.name, 3)
    },
    StateEmail() {
      return EmailValidator(this.email)
    },
    StateMessage() {
      return LengthValidator.Max(this.message, 10)
    },
    StatelAll(){
        return !(this.StateName && this.StateEmail && this.StateMessage)
    }
  },
  watch: {
    dataUser: {
      handler(){
        this.$emit('changeMeta', this.metaData);
        this.footer_messages = this.dataUser.options.footer_messages;
        this.verifyDataUser()
      }
    }
  },
};
</script>


<style lang="scss">

#c {

    margin:0px;

    svg {
    width: 24px;
    fill: #000000;
    }

    .alert-success {
        width: 100%; 
      }

    .contactBigData{
      margin: 24px 0;
      padding: 32px;
      gap: 16px;
      width: 100%;
      background: #FFFFFF;
      border-radius: 6px;

      .addresses {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        gap: 24px;

        @media screen and (max-width: 767px) {
            width: 100%;
            display: flex;
            justify-content: flex-start;
            flex-wrap: wrap;
        }

        .address {
          display: flex;
          align-items: flex-start;
          gap: 5px;

            svg#Layer_1 {
                position: relative;
                top: 2px;
            }

            .text {
                padding-right: 10px;
            }

        }


      }

    }
}

.contact{
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 28px;
  flex: 1 1 0;

  @media screen and (max-width: 767px) {
    flex-wrap: wrap;
  }

  .form {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 12px;
    width: 100%;

        .send {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding: 12px 24px;
            background-color: var(--s-button-dark);
            border: none;
            border-radius: 5px;
            font-family: 'Inter';
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 17px;
            color: #FFFFFF !important;                        
        }
  }

  a, a:hover {
    color: #000;
    text-decoration: none;
  }

  .contactData {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 32px;
    gap: 16px;
    width: 25%;
    background: #FFFFFF;
    border-radius: 6px;

    @media screen and (max-width: 1279px) {
        width: 35%;
    }

    @media screen and (max-width: 991px) {
        width: 50%;
    }

    @media screen and (max-width: 767px) {
        width: 100%;
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-around;
        flex-wrap: wrap;
    }

    @media screen and (max-width: 400px) {
        justify-content: flex-end;
    }

      .socialgrid {
        display: flex;
        flex-direction: row;
        gap: 5px;
        align-self: center;
      }

      .phonegrid {
          margin-top: 14px;
          display: flex;
          flex-direction: column;
          gap: 7px;

          @media screen and (max-width: 767px) { margin-top: 0; }
          @media screen and (max-width: 400px) { margin-top: 14px; }

      }

  }

}
</style>