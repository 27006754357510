<template>
    <iframe
      :src="`https://maps.google.com/maps?f=q&source=s_q&hl=en&geocode=&q=${gmaps}&amp;output=embed`"
      width="100%"
      height="100%"
      frameborder="0"
      scrolling="no"
      marginheight="0"
      marginwidth="0"
      class="googleMaps"
      v-if="address != undefined"
    ></iframe>
</template>

<script>
var mixin = {
  data(){
    return {
      reloadNumberGoogle:0,
      google_maps: undefined,
    }
  },
  methods:{
    showInGoogleMaps(item){
      if(item != undefined){
        this.reloadNumberGoogle++;
        this.google_maps = item
      }
    },
  },
}
export default {
  mixin,
  name: "GoogleMaps",
  props: {
    address: Object,
    reloadNumber: Number,
  },
  data() {
    return {
      gmaps: undefined
    };
  },
  methods: {
    googleMaps(){
      this.gmaps = this.googleMapsUrl(
        this.address.street,
        this.address.number,
        this.address.zipcode,
        this.address.city,
        this.address.state
      );
    },
    googleMapsUrl(address, number, zipcode, city, state) {
      return (
        address + "," + number + " " + zipcode + " " + city + "/" + state
      );
    },
  },
  watch: {
    reloadNumber: {
      handler() {
        this.googleMaps();
      },
    },
  },
};
</script>
